import React from 'react'
import { Link } from 'gatsby'
import { useState } from 'react'
import cx from 'classnames'

import HeaderMenu from './component/HeaderMenu'

import spoonxLogo from '../../../images/logo-mobile.png'
import openBtn from '../../../images/menu.png'
import closeBtn from '../../../images/close.png'

import './style.scss'

const HeaderMobile = ({ menus }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const handleClick = () => {
    setMenuVisible(!menuVisible)
  }

  return (
    <div className="navbar-mobile">
      <div className="navbar-mobile-header">
        <Link to="/">
          <img src={spoonxLogo} alt="spoonx-logo" className="navbar-logo" />
        </Link>
        <img
          src={menuVisible ? closeBtn : openBtn}
          alt="close-button"
          className="menu-btn"
          onClick={handleClick}
          aria-hidden="true"
        />
      </div>
      <HeaderMenu
        menus={menus}
        visible={menuVisible}
        className="navbar-mobile-wrapper"
      />
      <div className={cx('mask', { 'show-mask': menuVisible })} />
    </div>
  )
}

export default HeaderMobile
