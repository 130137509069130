import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { PATH } from '../../../constants'
import spoonxLogo from '../../../images/logo-pc.png'

import './style.scss'

const HeaderPC = ({ menus }) => {
  const [pathname, setPathname] = useState('')

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  return (
    <div className="navbar-pc">
      <Link to={PATH.home}>
        <img src={spoonxLogo} alt="navbar-logo" className="navbar-logo" />
      </Link>
      <div className="item-wrapper">
        {menus.map(item => {
          const isBtn = item.key === 'btn'
          const isSelected = pathname.includes(item.to)

          if (item.subMenus) {
            return (
              <span
                key={item.name}
                className={cx('navbar-item', {
                  'active-navbar-item': isSelected,
                })}
              >
                {item.name}
                <div className="sub-menu-container">
                  <div className="sub-menu-list">
                    {item.subMenus.map(subMenu => (
                      <Link
                        to={subMenu.to}
                        key={subMenu.name}
                        className="sub-menu-item"
                        activeClassName="active-sub-menu-item"
                      >
                        {subMenu.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </span>
            )
          }

          return (
            <Link
              to={item.to}
              key={item.name}
              className={cx('navbar-item', { 'navbar-item-btn': isBtn })}
              activeClassName={cx('active-navbar-item', {
                'active-navbar-item-btn': isBtn,
              })}
            >
              {item.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default HeaderPC
