import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import downArrowIcon from '../../../../../images/down-arrow.png'

import './style.scss'

const MenuItem = ({ item }) => {
  const [pathname, setPathname] = useState('')
  const [showMore, setShowMore] = useState(true)
  const isSelected = pathname.includes(item.to)

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  return (
    <div className="menu-container">
      <div
        key={item.name}
        className={cx('navbar-item', { 'active-navbar-item': isSelected })}
        onClick={() => setShowMore(!showMore)}
      >
        {item.name}
        <img
          src={downArrowIcon}
          className={cx('down-arrow-icon', { 'up-arrow-icon': showMore })}
        />
      </div>
      <div className={cx('sub-menu-list', { 'show-sub-menu-list': showMore })}>
        {item.subMenus.map(subMenu => (
          <Link
            to={subMenu.to}
            key={subMenu.name}
            className="sub-menu-item"
            activeClassName="active-sub-menu-item"
          >
            {subMenu.name}
          </Link>
        ))}
      </div>
    </div>
  )
}

const HeaderMenu = props => {
  const { visible = false, menus = [] } = props

  return (
    <div className={cx('item-wrapper', { 'item-wrapper-nav': visible })}>
      {menus.map(item => {
        if (item.subMenus) {
          return <MenuItem key={item.name} item={item} />
        }

        return (
          <Link
            to={item.to}
            key={item.name}
            className="navbar-item"
            activeClassName="active-navbar-item"
          >
            {item.name}
          </Link>
        )
      })}
    </div>
  )
}
export default HeaderMenu
