import { useState, useEffect, useLayoutEffect } from 'react'

import '../styles/animation.scss'

const ANIMATION_BEFORE_CLASS = 'spoonx-animation-before'

const useAnimation = (rootSelector, targetElementSelector, options = {}) => {
  const {
    animationClass = 'spoonx-animation',
    animationEndClass = 'spoonx-animation-end',
  } = options
  const [root, setRoot] = useState()
  const [targetElement, setTargetElement] = useState()

  useLayoutEffect(() => {
    if (rootSelector) {
      const nextRoot = document.querySelector(rootSelector)
      if (nextRoot) {
        setRoot(nextRoot)
      }
    }

    if (targetElementSelector) {
      const nextTargetElement = document.querySelector(targetElementSelector)
      if (nextTargetElement) {
        nextTargetElement.className = `${nextTargetElement.className} ${ANIMATION_BEFORE_CLASS}`.trim()
        setTargetElement(nextTargetElement)
      }
    }
  }, [rootSelector, targetElementSelector])

  useEffect(() => {
    if (!root || !targetElement) {
      return
    }
    const io = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          targetElement.className = `${targetElement.className.replace(
            ANIMATION_BEFORE_CLASS,
            ''
          )} ${animationClass}`.trim()
          io.unobserve(targetElement)
          io.disconnect()
        }
      },
      {
        root: root,
        threshold: 0.1,
      }
    )

    io.observe(targetElement)

    targetElement.addEventListener('animationend', () => {
      targetElement.className = `${targetElement.className} ${animationEndClass}`
    })
  }, [root, targetElement, animationClass, animationEndClass])
}

export default useAnimation
