import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import Footer from '../Footer'
import SEO from '../seo'

import './style.scss'

const Layout = ({ id, children, className }) => {
  return (
    <div className="layout-wrpper">
      <SEO title="SpoonX" description="" keywords="" />
      <Header />
      <div className="layout-content">
        <main className={className} id={id}>
          {children}
          <Footer />
        </main>
      </div>
    </div>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
